/* eslint-disable no-mixed-spaces-and-tabs */
import {
	EmailTemplateWrapper,
	Registration,
	RegistrationDate,
	RegistrationField,
	RegistrationsObject,
	RegistrationSpeaker,
	RegistrationStatistic,
	RegistrationUser,
	RegistrationUserWithData,
	UsersObject
} from '@/models/Registration';
import {RegistrationForm} from '@/models/RegistrationForm';
import axios from 'axios';
import {secureRandom} from '@/utils/secureRandom';
import qs from 'qs';
import {Email} from '@/models/api';
import {Column} from '@/utils/excel';
import {
	convertBoolString,
	dateToDateTimeString,
	durationStrWithSeconds,
	formatDateCreated,
	formatStatus,
	formatType,
	minDurationReached,
	translateSalutation
} from '@/utils/filters';
import {FormMail} from '@/models/Survey';
import Base from "@/views/Base";
import {getCurrentLanguage} from "@/utils/languages";
import store from "@/store/index";
import {Theme} from "@/models/Theme";
import {Account} from "@/models/Account";
import {FormCustomField} from "@/models/CustomField";

/**
 * API calls for registration
 * @module service/RegistrationService
 */
export default {
	// GET
	getRegistrations(
		page: number,
		size: number,
		order: string,
		direction: 'ASC' | 'DESC',
		search?: string
	): Promise<RegistrationsObject> {
		const params: { [key: string]: string | number } = {
			page,
			size,
			order,
			direction
		};
		if (search) params['search'] = search;
		return axios
			.get('/registration', { params })
			.then((response) => response.data);
	},
	getActiveRegistrations(
		page: number,
		size: number,
		order: string,
		direction: 'ASC' | 'DESC',
		search?: string
	): Promise<RegistrationsObject> {
		const params: { [key: string]: string | number } = {
			page,
			size,
			order,
			direction
		};
		if (search) params['search'] = search;
		return axios
			.get('/registration/listActive', { params })
			.then((response) => response.data);
	},
	getPastRegistrations(
		page: number,
		size: number,
		order: string,
		direction: 'ASC' | 'DESC',
		search?: string
	): Promise<RegistrationsObject> {
		const params: { [key: string]: string | number } = {
			page,
			size,
			order,
			direction
		};
		if (search) params['search'] = search;
		return axios
			.get('/registration/listPast', { params })
			.then((response) => response.data);
	},
	getRegistrationStatistics(): Promise<RegistrationStatistic> {
		return axios.get('/registration/statistic').then((res) => res.data);
	},
	getRegistration(id: string | number): Promise<Registration> {
		return axios.get('/registration/' + id).then((response) => response.data);
	},
	getRegistrationUsers(
		id: number,
		page: number,
		size: number,
		order: string,
		direction: 'ASC' | 'DESC'
	): Promise<UsersObject> {
		const params: { [key: string]: string | number } = {
			page,
			size,
			order,
			direction
		};
		return axios
			.get(`/registration/${id}/user`, { params })
			.then((res) => res.data);
	},
	getRegistrationUsersForDate(
		id: number,
		regId: number,
		page: number,
		size: number,
		order: string | null,
		direction: 'ASC' | 'DESC' | null,
		search?: string
	): Promise<UsersObject> {
		const params: { [key: string]: string | number | null } = {
			page,
			size,
			order,
			direction
		};
		if (search && search !== '') params['search'] = search;
		return axios
			.get(`/registration/${regId}/date/${id}/user`, { params })
			.then((res) => res.data);
	},
	getEmailTemplates(formtype: string): Promise<EmailTemplateWrapper> {
		formtype = formtype ? '/' + formtype : '';
		return axios.get(`/mail-text-template${formtype}`).then((res) => res.data);
	},
	getDoiEmailTemplates(): Promise<EmailTemplateWrapper> {
		return axios.get(`/mail-text-template/doi`).then((res) => res.data);
	},
	getSpeakerImageUrl(regId: number, id: number): string {
		return `${axios.defaults.baseURL}/registration/${regId}/speaker/${id}/photo`;
	},
	getRegUsersForDateWithData(
		regId: number,
		id: number
	): Promise<Array<RegistrationUserWithData>> {
		return axios
			.get(
				`/registration/${regId}/date/${id}/registeredUserWithParticipationData`
			)
			.then((res) => res.data);
	},
	getRegUsersAndParticipantsForDateWithData(
		regId: number,
		id: number
	): Promise<Array<RegistrationUserWithData>> {
		return axios
			.get(`/registration/${regId}/date/${id}/allUserWithParticipationData`)
			.then((res) => res.data);
	},

	// UPDATE
	updateGeneral(id: number, registration: Registration): Promise<Registration> {
		return axios
			.put(`/registration/${id}`, registration)
			.then((res) => res.data);
	},
	updateDate(
		regId: number,
		id: number,
		date: RegistrationDate
	): Promise<RegistrationDate> {
		return axios
			.put(`/registration/${regId}/date/${id}`, date)
			.then((res) => res.data);
	},
	updateField(
		regId: number,
		id: number,
		field: RegistrationField
	): Promise<RegistrationField> {
		return axios
			.put(`/registration/${regId}/field/${id}`, field)
			.then((res) => res.data);
	},
	updateSpeaker(
		regId: number,
		id: number,
		speaker: RegistrationSpeaker
	): Promise<RegistrationSpeaker> {
		return axios
			.put(`/registration/${regId}/speaker/${id}`, speaker)
			.then((res) => res.data);
	},
	updateUser(
		regId: number,
		id: number,
		user: RegistrationUser
	): Promise<RegistrationUser> {
		return axios
			.put(`/registration/${regId}/user/${id}`, user)
			.then((res) => res.data);
	},

	// ADD
	addRegistration(registration: Registration): Promise<Registration> {
		return axios
			.post('/registration', registration)
			.then((response) => response.data);
	},
	addDate(regId: number, date: RegistrationDate): Promise<RegistrationDate> {
		return axios
			.post(`/registration/${regId}/date`, date)
			.then((res) => res.data);
	},
	addField(
		regId: number,
		field: RegistrationField
	): Promise<RegistrationField> {
		return axios
			.post(`/registration/${regId}/field`, field)
			.then((res) => res.data);
	},
	addSpeaker(
		regId: number,
		speaker: RegistrationSpeaker
	): Promise<RegistrationSpeaker> {
		return axios
			.post(`/registration/${regId}/speaker`, speaker)
			.then((response) => response.data);
	},
	addImage(
		speakerId: number,
		regId: number,
		blob: Blob
	): Promise<Registration> {
		const form = new FormData();
		form.append('file', blob);
		const headers = {
			'Content-Type': 'multipart/form-data'
		};
		return axios
			.post(`/registration/${regId}/speaker/${speakerId}/image`, form, {
				headers
			})
			.then((response) => response.data);
	},
	addUser(regId: number, user: RegistrationUser): Promise<RegistrationUser> {
		return axios
			.post(`/registration/${regId}/user`, user)
			.then((response) => response.data);
	},
	addUsers(
		regId: number,
		users: RegistrationUser[]
	): Promise<RegistrationUser> {
		return axios
			.post(`/registration/${regId}/user/createBatch`, { commandList: users })
			.then((response) => response.data);
	},
	sendEmailToDateUsers(
		regId: number,
		id: number,
		mail: Email,
		recipientIds: string[]
	): Promise<void> {
		return axios
			.post(`/registration/${regId}/date/${id}/user/mail`, {
				...mail,
				toAll: recipientIds.length === 0,
				recipientIds: recipientIds.map((id) => +id)
			})
			.then();
	},
	sendTestEmailToAdmin(
		regId: number,
		id: number,
		mail: Email,
		recipientIds: string[]
	): Promise<void> {
		return axios
			.post(`/registration/${regId}/date/${id}/user/testMail`, {
				...mail,
				toAll: recipientIds.length === 0,
				recipientIds: recipientIds.map((id) => +id)
			})
			.then();
	},
	addFile(blob: Blob): Promise<string> {
		const form = new FormData();
		form.append('file', blob);
		const headers = {
			'Content-Type': 'multipart/form-data'
		};
		return axios
			.post(`/file/uploadTempFile`, form, { headers })
			.then((response) => response.data);
	},
	duplicateRegistration(regId: number): Promise<Registration> {
		return axios
			.post(`/registration/${regId}/duplicate`)
			.then((res) => res.data);
	},

	// DELETE
	deleteRegistration(id: number): Promise<void> {
		return axios.delete('/registration/' + id).then();
	},
	deleteDate(regId: number, id: number): Promise<void> {
		return axios.delete(`/registration/${regId}/date/${id}`).then();
	},
	deleteField(regId: number, id: number): Promise<void> {
		return axios.delete(`/registration/${regId}/field/${id}`).then();
	},
	deleteSpeaker(regId: number, id: number): Promise<void> {
		return axios.delete(`/registration/${regId}/speaker/${id}`).then();
	},
	deleteImage(regId: number, id: number): Promise<void> {
		return axios.delete(`/registration/${regId}/speaker/${id}/image`).then();
	},
	deleteUser(regId: number, id: number): Promise<void> {
		return axios.delete(`/registration/${regId}/user/${id}`).then();
	},
	deleteUsersForDate(regId: number, id: number): Promise<void> {
		return axios.delete(`/registration/${regId}/date/${id}/user`).then();
	},
	deleteSelectedUsersForDate(
		regId: number,
		id: number,
		ids: number[]
	): Promise<void> {
		return axios
			.delete(`/registration/${regId}/date/${id}/user/selectedUser`, {
				params: { ids: ids },
				paramsSerializer: (params) => qs.stringify(params, {arrayFormat: 'repeat'})
			})
			.then();
	},
	deleteAllPastRegisteredUsers(): Promise<void> {
		return axios.delete(`/registration/pastUsers`).then();
	},
	deleteAllPastRegistrationDates(): Promise<void> {
		return axios.delete(`/registration/pastDates`).then();
	},
	deleteAllPastRegistrationForms(): Promise<void> {
		return axios.delete(`/registration/pastForms`).then();
	},
	deleteBookingFromDate(regId: number, id: number): Promise<void> {
		return axios.delete(`/registration/${regId}/date/${id}/booking`).then();
	},
	activateForm(id: number): Promise<void> {
		return axios.put(`/registration/${id}/activate`).then();
	},
	deactivateForm(id: number): Promise<void> {
		return axios.put(`/registration/${id}/deactivate`).then();
	},
	addCertificate(regId: number, dateId: number, file: File): Promise<void> {
		const form = new FormData();
		form.append('file', file);
		const headers = { 'Content-Type': 'multipart/form-data' };
		return axios.post(`/registration/${regId}/date/${dateId}/certificateTemplate`, form, {
			headers
		});
	},
	deleteCertificate(regId: number, dateId: number, filename: string): Promise<void> {
		return axios
			.delete(`/registration/${regId}/date/${dateId}/certificateTemplate/${filename}`)
			.then();
	},
	sendCertificate(userId: number, regId: number, dateId: number, mail: FormMail): Promise<void> {
		return axios
			.post(`/registration/${regId}/user/${userId}/certificate/${dateId}/mail`, mail)
			.then();
	},
	sendTestCertificate(regId: number, dateId: number, mail: FormMail): Promise<void> {
		return axios
			.post(`/registration/${regId}/user/certificate/${dateId}/testMail`, mail)
			.then();
	},
	downloadCertificate(regId: number, userId: number, dateId: number): Promise<Blob> {
		return axios
			.get(
				`/registration/${regId}/user/${userId}/certificate/${dateId}/certificate.pdf`,
				{ responseType: 'blob' }
			)
			.then(res => res.data);
	},
	downloadExampleCertificate(regId: number, dateId: number): Promise<Blob> {
		return axios
			.get(
				`/registration/${regId}/date/${dateId}/certificateExample.pdf`,
				{ responseType: 'blob' }
			)
			.then(res => res.data);
	},
	isCertificatesDownloadOK(formId: number, dateId: number): Promise<boolean> {
		return axios.get(`/registration/${formId}/date/${dateId}/isDownloadOK`).then(res => res.data)
	},
	sendCertificateToAllParticipants(regId: number, dateId: number, mail: FormMail): Promise<void> {
		return axios
			.post(`/registration/${regId}/date/${dateId}/user/certificateMail`, mail)
			.then();
	},
	registrationUrlExists(url: string): Promise<boolean> {
		return axios.get('/registration/exists?url=/' + url)
        	.then((res) => res.data)
	},

	// FUNCTIONS
	newRegistrationForm(): RegistrationForm {
		const id = () => secureRandom().toString(36).substr(2);
		return {
			general: {
				title: '',
				description: '',
				language: getCurrentLanguage(),
				branding: null,
				subtitle: '',
				url: id(),
				dateCreated: 0,
				label: '',
				endUrl: this.getEndUrlFromStore()
			},
			dates: [this.newDate(1)],
			mail: {
				replyTo: '',
				subject: '',
				text: '',
				addOutlookCalendarEntry: true,
				bcc: '',
				pin: '',
				sendReminderMails: true,
				mailTextTemplateId: ''
			},
			allowMultipleDateSelection: true,
			participation: {
				address: {
					enabled: false,
					required: false
				},
				comment: {
					enabled: false,
					required: false
				},
				company: {
					enabled: false,
					required: false
				},
				partnerId: {
					enabled: false,
					required: false
				},
				salutation: {
					enabled: true,
					required: false
				},
				vvId: {
					enabled: false,
					required: false
				},
				email: {},
				name: {},
				phone: {
					enabled: true,
					required: true
				},
				fields: [],
				customFields: [],
				checkboxes: []
			},
			speakers: [{ name: '', id: id(), idx: 0 }],
			hasParticipants: false,
			legal: {
				imprintCompany: '',
				imprintName: '',
				privacyURL: ''
			},
			doi: {
				mailTextTemplateId: -1,
				replyTo: '',
				sendReminderMail: false,
				subject: '',
				text: '',
				type: 'DISABLED',
				newsletterText: ''
			},
			// certificate: {
			// 	certificateFileName: '',
			// 	generateCert: false,
			// 	minDuration: 0
			// }
		};
	},

	newDate(index: number): RegistrationDate {
		const p = 60 * 60 * 1000;
		const roundNowToHour = () => Math.floor(Date.now() / p) * p;
		return {
			id: Date.now() + Math.floor(secureRandom()),
			title: '',
			start: roundNowToHour() + 1000 * 60 * 60 * 2,
			end: roundNowToHour() + 1000 * 60 * 60 * 3,
			open: true,
			lastRegistration: 20,
			userLimit: -1,
			countdown: 0,
			edit: false,
			dateCreated: Date.now(),
			generateCert: false
		};
	},

	getEndUrlFromStore(): string {
		const theme = store.getters.allThemes.find((theme: Theme) => {
			const dataConferenceURL = (store.getters.account as Account).customerShortDTO?.dataConferenceURL;
			const subdomain = dataConferenceURL?.replaceAll(/.csn(?:dev)?.de/gm, '');
			return subdomain === 'https://' + theme.subdomain;
		});
		return theme?.weblink?.endUrl || '';
	},

	getRegFormColumns(
		registration: Registration | null,
		participants: RegistrationUserWithData[],
		generateCert= false,
		instance: Base
	): Array<Column> {
		if (!registration) return [];
		const columns: Array<Column> = [
			{ header: instance.t('common.salutation') as string, key: 'salutation', width: 12 },
			{ header: instance.t('common.firstname') as string, key: 'firstname', width: 15 },
			{ header: instance.t('common.lastname')as string, key: 'lastname', width: 15 },
			{ header: instance.t('common.phoneNumber') as string, key: 'phone', width: 20 }
		];

		addCommonColumns(registration, participants, columns, instance);

		if (generateCert) columns.push({header: instance.$t('forms.certificateSentShort') as string, key: 'dateCertMailSent', width: 25})

		const uniqueUrlParamKeys = Array.from(
			new Set(
				participants
					.flatMap(p => p.customUrlParams || [])
					.map(p => p.key)
			)
		);

		if (uniqueUrlParamKeys.length > 0) {
			uniqueUrlParamKeys.forEach(key => {
				columns.push({ header: key, key: key, width: 15 });
			});
		}

		return columns;
	},

	getExtendedRegFormColumns(
		registration: Registration | null,
		participants: RegistrationUserWithData[],
		generateCert= false,
		instance: Base
	): Array<Column> {
		if (!registration) return [];
		const columns: Array<Column> = [
			{ header: instance.t('common.name'), key: 'name', width: 22 },
			{ header: instance.t('common.phoneNumber'), key: 'phone', width: 20 },
			{ header: instance.t('common.startTime'), key: 'start', width: 18 },
			{ header: instance.t('common.duration'), key: 'duration', width: 10 },
			{ header: '>' + instance.$t('common.nMinutes', {number: 15}), key: 'minDurationReached', width: 10 },
			{ header: instance.t('common.type'), key: 'type', width: 18 },
			{ header: instance.t('common.status'), key: 'status', width: 12 },
			{ header: instance.t('common.firstname'), key: 'firstname', width: 15 },
			{ header: instance.t('common.lastname'), key: 'lastname', width: 15 }
		];
		addCommonColumns(registration, participants, columns, instance);
		if (generateCert) columns.push({header: instance.t('forms.certificateSentShort'), key: 'dateCertMailSent', width: 25})
		return columns;
	},

	regUserWithDataToExcelEntryByDate(regUser: RegistrationUserWithData, dateId: number, instance: Vue): any {
		const entry = this.regUserWithDataToExcelEntry(regUser, instance);
		if (!regUser.certInfo) return entry;
		const certInfo = regUser.certInfo.find(certI => certI.regDateId === dateId);
		return {
			...entry,
			dateCertMailSent: certInfo ? getMostRecentDate(certInfo.dateCertMailSent, certInfo.dateCertMailToAllSent) : '-'
		}
	},

	regUserWithDataToExcelEntry(regUser: RegistrationUserWithData, instance: Vue): any {
		const participant = regUser.summarizedParticipant;
		const entry: any = {
			name: participant?.name,
			phone: participant?.phone || regUser.phone,
			start:
				participant?.status === 'DIALOUT_FAILED' || participant?.status === 'DIALOUT_BUSY'
					? '-'
					: dateToDateTimeString(participant?.beginDate, true),
			duration: durationStrWithSeconds(participant?.duration),
			minDurationReached: minDurationReached(participant?.duration, instance),
			type: formatType(participant?.type, instance),
			status: formatStatus(participant?.status, instance),
			salutation: translateSalutation(regUser.salutation, instance),
			firstname: regUser.firstname,
			lastname: regUser.lastname,
			addressStreet: regUser.addressStreet,
			addressCity: regUser.addressCity,
			company: regUser.company,
			email: regUser.email || participant?.email,
			partnerId: regUser.partnerId,
			comment: regUser.comment,
			dateCreated: dateToDateTimeString(regUser.dateCreated),
			dateDoi: dateToDateTimeString(regUser.dateDoi),
			vvId: regUser.vvId
		};

		if (regUser.values)
			regUser.values.forEach(
				(value) =>
					(entry[value.fieldId] = convertBoolString(
						!value.value ? '' : (value.value as string)
					))
			);

		if (regUser.customValues)
			regUser.customValues.forEach(
				(value) =>
					(entry[value.fieldId] = !value.value ? '' : (value.value as string)
					)
			);
		if (regUser.customUrlParams) regUser.customUrlParams.forEach(value => entry[value.key] = value.value);
		return entry;
	},

	registrationFormToRegistration(form: RegistrationForm): Registration {
		const fields = form.participation.fields.filter((f) => f.title !== '');
		const checkboxes = form.participation.checkboxes.filter(
			(f) => f.title !== ''
		);
		const speakers = form.speakers.filter((sp) => !!sp.image);

		return {
			id: null,
			dateCreated: form.general.dateCreated,
			address: !!form.participation.address.enabled,
			addressRequired: !!form.participation.address.required,
			comment: !!form.participation.comment.enabled,
			commentRequired: !!form.participation.comment.required,
			phone: !!form.participation.phone.enabled,
			phoneRequired: !!form.participation.phone.required,
			company: !!form.participation.company.enabled,
			companyRequired: !!form.participation.company.required,
			partnerId: !!form.participation.partnerId.enabled,
			partnerIdRequired: !!form.participation.partnerId.required,
			salutation: !!form.participation.salutation.enabled,
			salutationRequired: !!form.participation.salutation.required,
			vvId: !!form.participation.vvId.enabled,
			vvIdRequired: !!form.participation.vvId.required,

			allowMultipleDateSelection: form.allowMultipleDateSelection,
			fields: [...fields, ...checkboxes],
			customFields: form.participation.customFields.filter(i=>i.enabled).map(i=>{
				return {id: i.customFieldId, title: "", required: i.required, ignoreCase: true, regex: "", description: "", index: 0}
			}),
			title: form.general.title,
			subTitle: form.general.subtitle,
			description: form.general.description,
			endUrl: form.general.endUrl,
			dates: form.dates.map((date) => ({
				...date,
				description: date.description
			})),
			regDates: form.dates.map((date) => ({
				...date,
				description: date.description
			})),
			speakers,
			language: form.general.language,
			brandingId: form.general.branding,
			countRegisteredUser: 0,
			url: '/' + form.general.url,
			legal: form.legal,
			mail: form.mail,
			active: true,
			ignoreBlacklist: false,
			label: form.general.label,
			doi: form.doi,
			// certificateFileName: form.certificate.certificateFileName,
			// generateCert: form.certificate.generateCert,
			// minDurationForCert: form.certificate.minDuration
		};
	},

	registrationToRegistrationForm(registration: Registration): RegistrationForm {
		const dates =
			registration.regDates
				?.map((r) => ({
					...r,
					open: true,
					hasCountdown: (r.countdown && r.countdown > -1) as boolean,
					hasLimit: (r.userLimit && r.userLimit > -1) as boolean
				}))
				.sort((a, b) => a.start - b.start) || [];
		return {
			general: {
				title: registration.title,
				description: registration.description || '',
				language: registration.language.toUpperCase(),
				branding: registration.branding ? registration.branding?.id + '' : null,
				subtitle: registration.subTitle || '',
				url: registration.url.substr(1),
				dateCreated: registration.dateCreated,
				label: registration.label || '',
				endUrl: registration.endUrl || ''
			},
			dates: dates.map((date) => ({
				start: date.start,
				end: date.end,
				title: date.title,
				countRegisteredUser: date.countRegisteredUser,
				countdown: date.countdown,
				description: date.description,
				id: date.id,
				lastRegistration: date.lastRegistration,
				open: date.open,
				userLimit: date.userLimit,
				hasCountdown: date.hasCountdown,
				hasLimit: date.hasLimit,
				bookingId: date.bookingId,
				generateCert: date.generateCert
			})),
			mail: {
				replyTo: registration.mail.replyTo || '',
				subject: registration.mail.subject || '',
				text: registration.mail.text || '',
				addOutlookCalendarEntry: registration.mail.addOutlookCalendarEntry,
				bcc: registration.mail.bcc || '',
				pin: registration.mail.pin || '',
				sendReminderMails: registration.mail.sendReminderMails,
				mailTextTemplateId: registration.mail.mailTextTemplateId
			},
			allowMultipleDateSelection: registration.allowMultipleDateSelection,
			participation: {
				address: {
					enabled: registration.address,
					required: registration.addressRequired
				},
				comment: {
					enabled: registration.comment,
					required: registration.commentRequired
				},
				company: {
					enabled: registration.company,
					required: registration.companyRequired
				},
				partnerId: {
					enabled: registration.partnerId,
					required: registration.partnerIdRequired
				},
				salutation: {
					enabled: registration.salutation,
					required: registration.salutationRequired
				},
				vvId: {
					enabled: registration.vvId,
					required: registration.vvIdRequired
				},
				email: {},
				name: {},
				phone: {
					enabled: registration.phone,
					required: registration.phoneRequired
				},
				fields:
					registration.fields
						?.filter((f) => f.type !== 'CHECKBOX')
						.sort((a, b) => (a.idx < b.idx ? -1 : 1)) || [],
				checkboxes:
					registration.fields?.filter((f) => f.type === 'CHECKBOX') || [],
				customFields: (registration.customFields || []).map(i=>{
					return {
						customFieldId: i.id,
						required: i.required,
						enabled: true
					};
				})
			},
			hasParticipants: registration.countRegisteredUser > 0,
			speakers: registration.speakers
				? [
						...registration.speakers.map((sp) => ({
							id: sp.id,
							name: sp.name,
							idx: sp.idx
						})),
						{ name: '', idx: registration.speakers.length }
				  ]
				: [{ name: '', idx: 0 }],
			legal: registration.legal
				? {
						imprintCompany: registration.legal.imprintCompany || '',
						imprintName: registration.legal.imprintName || '',
						privacyURL: registration.legal.privacyURL || ''
				  }
				: { imprintCompany: '', imprintName: '', privacyURL: '' },
			doi: registration.doi,
			// certificate: {
			// 	certificateFileName: registration.certificateFileName || '',
			// 	generateCert: !!registration.generateCert,
			// 	minDuration: registration.minDurationForCert || 0
			// }
		};
	}
};

function addCommonColumns(
	registration: Registration,
	participants: RegistrationUserWithData[],
	columns: Array<Column>,
	instance: Base
) {
	// if (registration.salutation || participants.filter(p => p.salutation).length > 0) columns.splice(7, 0, {header: "Anrede", key: "salutation", width: 12})
	const hasAddress =
		participants.filter((p) => p.addressCity).length > 0 ||
		participants.filter((p) => p.addressStreet).length > 0;
	if (registration.address || hasAddress)
		columns.push(
			{ header: instance.t('common.streetAndNumberShort'), key: 'addressStreet', width: 20 },
			{ header: instance.t('common.zipCodeAndCity'), key: 'addressCity', width: 20 }
		);
	if (registration.company || participants.filter((p) => p.company).length > 0)
		columns.push({ header: instance.t('common.company'), key: 'company', width: 29 });
	columns.push({ header: instance.t('common.emailAddress'), key: 'email', width: 40 });
	if (
		registration.partnerId ||
		participants.filter((p) => p.partnerId).length > 0
	)
		columns.push({ header: instance.t('forms.partnerID'), key: 'partnerId', width: 15 });
	columns.push({ header: instance.t('forms.registrationTime'), key: 'dateCreated', width: 18 });
	if (registration.comment || participants.filter(p => p.comment).length > 0) {
		columns.push({ header: instance.t('admin.notes'), key: 'comment', width: 30 });
	}
	if (registration.doi && registration.doi.type !== 'DISABLED') {
		columns.push({
			header: instance.t('forms.doiVerified'),
			key: 'dateDoi',
			width: 19
		});
	}
	if (registration.vvId || participants.filter((p) => p.vvId).length > 0)
		columns.push({ header: instance.t('forms.gutBeratenID'), key: 'vvId', width: 22 });
	if (registration.fields) {
		registration.fields
			.filter((field) => field.type !== 'CHECKBOX')
			.sort((a, b) => a.idx - b.idx)
			.forEach((field) => {
				columns.push({ header: field.title, key: field.id, width: 20 });
			});
		registration.fields
			.filter((field) => field.type === 'CHECKBOX')
			.sort((a, b) => a.idx - b.idx)
			.forEach((field) => {
				columns.push({ header: field.title, key: field.id, width: 30 });
			});
	}
	if (registration.customFields) {
		(registration.customFields as FormCustomField[])
			.forEach((field) => {
				columns.push({ header: field.title, key: field.id, width: 20 });
			});
	}

	console.log('participants', participants);

	const uniqueUrlParamKeys = Array.from(
		new Set(
			participants
				.flatMap(p => p.customUrlParams || [])
				.map(p => p.key)
		)
	);

	if (uniqueUrlParamKeys.length > 0) {
		uniqueUrlParamKeys.forEach(key => {
			columns.push({ header: key, key: key, width: 15 });
		});
	}
}

function getMostRecentDate(dateA: number, dateB: number): string {
	return (dateA || dateB)
		? formatDateCreated(Math.max(dateA || 0, dateB || 0))
		: '-';
}
