





























import Base from "@/views/Base";
import {Component, Prop, Watch} from "vue-property-decorator";
import ElementCard from "@/views/presentation/components/ElementCard.vue";
import draggable from "vuedraggable";
import {ElementListItem} from "@/models/Presentation";

@Component({
  components: {ElementCard, draggable}
})
export default class PresentationElementList extends Base {
  @Prop({type: Number, default: 3}) columns!: number;
  @Prop({required: true}) value!: ElementListItem[];
  @Prop() selectedElement!: ElementListItem | null;

  items: ElementListItem[] = [];
  dragging = false;

  created(): void {
    this.items = this.value;
  }

  @Watch('value', {immediate: true, deep: true})
  onItemsChanged(newItems: ElementListItem[]) {
    this.items = [...newItems];
  }

  updateItems() {
    this.dragging = false;
    this.$emit('input', this.items);
  }
}
