import AppLayout from '@/layouts/top/AppLayout.vue';
import {Nav} from '@/models/Nav';

export function setNavigation(layout: AppLayout): Nav {
	const $t = (key: string): string => layout.$t(key) as string;
	const $tc = (key: string, count: number): string => layout.$tc(key, count) as string;
	return {
		itemsLeft: [
			{
				ref: 'conferenceDropdown',
				title: $t('nav.conferences'),
				id: 'Conference',
				authenticated: true,
				role: 'user',
				ddItems: [
					{
						title: $t('conference.scheduledConferences'),
						icon: 'calendar',
						to: { name: 'Conference-Scheduled' },
						description: $t('nav.descriptions.scheduledConferences')
					},
					{
						title: $t('conference.pastConferences'),
						icon: 'calendar',
						to: { name: 'Conference-Past' },
						description: $t('nav.descriptions.plannedConferences')
					},
					{
						title: $t('conference.newConference'),
						icon: 'plus',
						to: { name: 'Conference-Booking' },
						description: $t('nav.descriptions.newConference'),
						hasDivider: true
					},
					{
						title: $t('conference.roomConferences'),
						icon: 'key',
						to: { name: 'Conference-Rooms' },
						description: $t('nav.descriptions.roomConferences')
					},
					{
						title: $t('conference.newConferenceRoom'),
						icon: 'plus',
						to: { name: 'Conference-Room-Booking' },
						description: $t('nav.descriptions.newConferenceRoom'),
						hasDivider: true
					},
					{
						title: $t('nav.dashboard'),
						icon: 'th-list',
						to: { name: 'Home' },
						description: $t('nav.descriptions.dashboard')
					}
				]
			},
			{
				ref: 'formsNavDropdown',
				title: $t('nav.forms'),
				id: 'Forms',
				authenticated: true,
				role: 'user',
				ddItems: [
					{
						title: $t('nav.overviewRegForms'),
						icon: 'clipboard',
						to: { name: 'Forms-Registration' },
						description: $t('nav.descriptions.overviewRegForms')
					},
					{
						title: $t('nav.overviewSurveyForms'),
						icon: 'poll',
						to: { name: 'Forms-Survey' },
						description: $t('nav.descriptions.overviewSurveyForms'),
						hasDivider: true
					},
					{
						title: $t('nav.customFields'),
						icon: 'check',
						to: { name: 'Custom-Fields' },
						description: $t('nav.descriptions.customField'),
					},
					{
						title: $tc('forms.brandings', 0),
						icon: 'paperclip',
						to: { name: 'Forms-Branding' },
						description: $t('nav.descriptions.brandings')
					},
				]
			},
			{
				ref: 'presentationDropdown',
				title: $t('nav.presentation'),
				authenticated: true,
				id: 'Presentation',
				role: 'user',
				ddItems: [
					{
						title: $t('nav.bookPresentation'),
						icon: 'play',
						href: 'https://www.csnstart.de',
						target: '_blank',
						active: false,
						description: $t('nav.descriptions.presentation'),
					},
					{
						title: $t('nav.presentation'),
						icon: 'presentation',
						to: {name: 'Presentation'},
						description: $t('nav.descriptions.presentationNew'),
						role: 'w2'
					},
					{
						title: $t('nav.businessCards'),
						icon: 'user',
						to: {name: 'Presentation-Speaker-Profile'},
						description: $t('nav.descriptions.businessCards'),
						role: 'w2'
					},
					{
						title: $t('nav.liveVideoBackgrounds'),
						icon: 'image',
						to: {name: 'Live-Video-Background-Index'},
						description: $t('nav.descriptions.liveVideoBackgrounds'),
						role: 'w2'
					},
				]
			},
			{
				ref: 'serviceDropdown',
				title: $t('nav.service'),
				id: 'Service',
				authenticated: true,
				role: 'user',
				ddItems: [
					{
						title: $t('nav.operatorServices'),
						icon: 'headphones',
						description: $t('nav.descriptions.operatorServices'),
						to: { name: 'Operator-Bookings' }
					},
					{
						title: $t('operator.bookOperator'),
						icon: 'plus',
						description: $t('nav.descriptions.bookOperator'),
						to: { name: 'Operator-Booking-New' },
						hasDivider: true
					},
					{
						title: $t('adressbook.adressbook'),
						icon: 'address-book',
						to: { name: 'Documents-Adressbook' },
						description: $t('nav.descriptions.adressbook')
					},
					{
						title: $t('conference.playbacks'),
						icon: 'volume-up',
						to: { name: 'Documents-Playbacks' },
						description: $t('nav.descriptions.playbacks')
					}
				]
			},
			{
				ref: 'usersDropdown',
				title: $t('nav.userManagement'),
				id: 'Users',
				authenticated: true,
				role: 'admin',
				ddItems: [
					{
						title: $t('admin.customers'),
						icon: 'building',
						to: { name: 'Management-Customers' },
						description: $t('admin.manageCustomer')
					},
					{
						title: $t('admin.users'),
						icon: 'users',
						to: { name: 'Management-Accounts' },
						description: $t('admin.manageUser')
					}
				]
			},
			{
				ref: 'superAdminDropdown',
				title: $t('nav.userManagement'),
				id: 'Management',
				authenticated: true,
				role: 'sadmin',
				ddItems: [
					{
						title: $t('admin.customers'),
						icon: 'building',
						to: { name: 'Management-Customers' },
						description: $t('admin.manageCustomer')
					},
					{
						title: $t('admin.users'),
						icon: 'users',
						to: { name: 'Management-Accounts' },
						description: $t('admin.manageUser')
					},
					{
						title: $t('admin.admins'),
						icon: 'users',
						to: { name: 'Management-Admins' },
						description: $t('admin.manageAdmins')
					}
				]
			},
			{
				ref: 'reportDropdown',
				title: $t('nav.report'),
				id: 'Report',
				authenticated: true,
				role: 'any_admin',
				ddItems: [
					{
						title: $t('conference.runningConferences'),
						icon: 'phone',
						to: { name: 'Report-Console-Running' },
						description: $t('nav.descriptions.runningConferencesAdmin')
					},
					{
						title: $t('conference.scheduledConferences'),
						icon: 'phone',
						to: { name: 'Report-Console-Scheduled' },
						description: $t('nav.descriptions.scheduledConferencesAdmin')
					},
					{
						title: $t('conference.pastConferences'),
						icon: 'phone',
						to: { name: 'Report-Console-Past' },
						description: $t('nav.descriptions.pastConferencesAdmin'),
						hasDivider: true
					},
					{
						title: $t('nav.operatorServices'),
						icon: 'headphones',
						to: { name: 'Report-Operator' },
						description: $t('nav.descriptions.operatorServices')
					},
					{
						title: $t('nav.registrations'),
						icon: 'clipboard',
						to: { name: 'Report-Registration-Forms' },
						description: $t('nav.descriptions.registrations')
					},
					{
						title: $t('nav.surveys'),
						icon: 'poll',
						to: { name: 'Admin-Surveys' },
						description: $t('nav.descriptions.surveys'),
					}
				]
			},
			{
				ref: 'systemDropdown',
				title: $t('nav.system'),
				id: 'System',
				authenticated: true,
				role: 'admin',
				ddItems: [
					{
						title: $t('conference.pastConferences'),
						icon: 'calendar',
						to: { name: 'Admin-Media' },
						description: $t('nav.descriptions.pastConferencesAdmin')
					}
				]
			},
			{
				ref: 'systemDropdown',
				title: $t('nav.system'),
				id: 'System',
				authenticated: true,
				role: 'sadmin',
				ddItems: [
					{
						title: $t('nav.news'),
						icon: 'th-list',
						to: { name: 'System-Design' },
						description: $t('nav.descriptions.news'),
						disabled: true
					},
					{
						title: $t('conference.dialNumbers'),
						icon: 'phone',
						to: { name: 'System-Numbers' },
						description: $t('nav.descriptions.dialNumbers'),
						hasDivider: true
					},
					{
						title: $t('nav.blacklist'),
						icon: 'user-times',
						to: { name: 'System-Media' },
						description: $t('nav.descriptions.blacklist'),
						disabled: true
					},
					{
						title: $t('nav.pinBlacklist'),
						icon: 'times-circle',
						to: { name: 'System-Media' },
						description: $t('nav.descriptions.pinBlacklist'),
						disabled: true
					}
				]
			},
			{
				ref: 'adminDropdown ',
				title: $t('nav.admin'),
				id: 'Admin',
				authenticated: true,
				role: 'user_creates',
				ddItems: [
					{
						title: $t('admin.users'),
						icon: 'users',
						to: { name: 'Management-Accounts' },
						description: $t('admin.manageUser')
					}
				]
			}
		],
		itemsRight: [
			{
				ref: 'user',
				title: layout.$store.getters.username,
				id: 'User',
				icon: { name: 'user-circle', type: 'b' },
				authenticated: true,
				ddItems: [
					{
						title: $t('nav.profile'),
						icon: 'user',
						to: { name: 'User-Profile' },
						description: $t('nav.descriptions.profile'),
						hasDivider: layout.prod
					},
					{
						title: $t('nav.supportCenter'),
						icon: 'question-outline',
						to: { name: 'User-Manual' },
						description: $t('nav.descriptions.supportCenter')
					},
					{
						title: $t('nav.serviceCenter'),
						icon: 'plus',
						to: { name: 'User-Extras' },
						description: $t('nav.descriptions.serviceCenter'),
						hasDivider: true
					}
					// {
					// 	title: 'Tarifoptimierung',
					// 	icon: 'paperclip',
					// 	to: { name: 'User-Discounts' },
					// 	description: 'Sondertarife',
					// 	hasDivider: true
					// }
				]
			},
			{
				ref: 'login',
				title: $t('nav.login'),
				id: 'Login',
				to: 'Login',
				icon: { name: 'user-circle', type: 'b' },
				authenticated: false
			}
		]
	};
}
