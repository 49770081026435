





























































































































import Base from "@/views/Base";
import {Component, Prop, Watch} from "vue-property-decorator";
import {quillEditor} from 'vue-quill-editor';
import {ElementListItem, ImportProcess, IndexForm, PresentationForm, WebVideoElementForm} from "@/models/Presentation";
import WSwitch from "@/components/WSwitch.vue";
import PresentationElementList from "@/views/presentation/components/ElementList.vue";
import PresentationElementTable from "@/views/presentation/components/ElementTable.vue";
import {presentationNotesEditorOptions} from "@/utils/quill";
import PresentationService from "@/service/PresentationService";
import WebVideoForm from "@/views/presentation/components/WebVideoForm.vue";
import {debounce} from "lodash";
import FileForm from "@/views/presentation/components/FileForm.vue";
import BatchEdit from "@/views/presentation/components/BatchEdit.vue";
import WProgressBar from "@/components/WProgressBar.vue";
import SurveyForm from "@/views/presentation/components/SurveyForm.vue";


@Component({
  components: {
    SurveyForm,
    WProgressBar,
    BatchEdit,
    FileForm,
    WebVideoForm,
    PresentationElementList,
    PresentationElementTable,
    WSwitch,
    quillEditor
  }
})
export default class PresentationElements extends Base {
  @Prop() form!: PresentationForm;

  selectedElement: null | ElementListItem = null;
  items: ElementListItem[] = [];

  showNotes = false;
  columns: string | number = 3;
  editIds: number[] = [];
  allSelected = false;
  editorOptions = presentationNotesEditorOptions;

  currentUploadingFile: File | null = null;
  uploadProgress = 0;
  abortController = new AbortController();

  importProcesses: ImportProcess[] = [];
  importProcessInterval: number | null = null;

  created() {
    this.clearImportInterval();
    this.importProcessInterval = window.setInterval(this.getImportProcesses, 1500);
  }

  mounted() {
    this.getElements();
    this.getImportProcesses();
    window.addEventListener('keydown', this.handleKeydown);
  }

  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeydown);
    this.clearImportInterval();
  }

  clearImportInterval() {
    if (this.importProcessInterval) window.clearInterval(this.importProcessInterval);
  }

  getElements() {
    PresentationService.getElements(this.form.id)
      .then(res => this.items = res.map(PresentationService.elementToListItem))
      .catch(this.showNetworkError);
  }

  getImportProcesses() {
    PresentationService.getImportProcesses(this.form.id)
      .then(res => {
        const previousStatuses = this.importProcesses.map(p => p.status);
        this.importProcesses = res;
        res.forEach((process, index) => {
          if (previousStatuses[index] !== 'FINISHED' && process.status === 'FINISHED') this.getElements();
        });
      })
      .catch(this.showNetworkError);
  }

  handleKeydown(event: KeyboardEvent) {
    let activeElement = document.activeElement;
    if (activeElement && (activeElement.tagName === 'INPUT' || activeElement.tagName === 'TEXTAREA' || activeElement.getAttribute('contenteditable') === 'true')) return;
    if (!this.selectedElement) return;

    const currentIndex = this.items.findIndex(item => item.id === this.selectedElement?.id);
    if ((event.key === 'ArrowRight' || event.key === 'ArrowDown') && currentIndex < this.items.length - 1) {
      this.setSelectedElement(this.items[currentIndex + 1]);
    } else if ((event.key === 'ArrowLeft' || event.key === 'ArrowUp') && currentIndex > 0) {
      this.setSelectedElement(this.items[currentIndex - 1]);
    }
  }

  createWebVideoElement(webVideoForm: WebVideoElementForm) {
    PresentationService.createWebVideoElement(this.form.id, webVideoForm)
      .then(res => {
        this.items.push(PresentationService.elementToListItem(res));
        this.$emit('update');
        this.toast(this.$t('presentation.webVideo.added') as string, 'success');
      })
      .catch(this.showNetworkError);
  }

  createSurveyFormElement(surveyId: number) {
    PresentationService.createSurveyFormElement(this.form.id, surveyId)
      .then(res => {
        this.items.push(PresentationService.elementToListItem(res));
        this.$emit('update');
        this.toast(this.$t('presentation.survey.added') as string, 'success');
      })
      .catch(this.showNetworkError);
  }

  rowSelected(item: ElementListItem, shiftKey = false): void {
    item.selected = !item.selected;
    if (item.selected) this.editIds.push(item.id);
    else this.editIds = this.editIds.filter(id => id !== item.id);
    if (shiftKey) this.selectBetween(item, item.selected);
  }

  @Watch('showNotes')
  onShowNotesChange(value: boolean) {
    if (value && !this.selectedElement) this.selectedElement = this.items[0];
  }

  @Watch('editIds', {deep: true})
  onEditIdsChanged() {
    this.allSelected = this.editIds.length === this.items.length && this.items.length > 0;
  }

  setSelectedElement(item: ElementListItem): void {
    if (this.selectedElement && this.selectedElement.id === item.id) {
      this.selectedElement = null;
      this.showNotes = false;
    } else {
      this.selectedElement = item;
      this.showNotes = true;
    }
  }

  updateIndexes(): void {
    let indexList = this.items.map((item, idx) => ({id: item.id, idx: idx + 1}) as IndexForm);
    PresentationService
      .updateElementIndexes(this.form.id, indexList)
      .then(res => this.items = res.map(PresentationService.elementToListItem))
      .catch(this.showNetworkError);
  }

  updateElement(item?: ElementListItem): void {
    const form = item
      ? PresentationService.elementListItemToForm(item, this.form.id)
      : this.selectedElement
        ? PresentationService.elementListItemToForm(this.selectedElement, this.form.id)
        : null;
    if (!form) return;
    PresentationService.updateElement(form)
      .then(() => this.toast(this.$t('presentation.elementSaved') as string, 'success'))
      .catch(this.showNetworkError);
  }

  debouncedUpdateElement = debounce(this.updateElement, 750);

  updateNotes(value: any) {
    if (!this.selectedElement) return;
    if (value.html === this.selectedElement.notes) return;
    this.selectedElement.notes = value.html;
    this.debouncedUpdateElement();
  }

  deleteElement(item: ElementListItem): void {
    this.$bvModal
      .msgBoxConfirm(
        this.$t('modals.deleteMaterial.description', {
          name: item.name
        }) as string,
        {
          okVariant: 'danger',
          okTitle: this.t('modals.deleteMaterial.ok'),
          cancelTitle: this.t('common.cancel'),
          centered: true,
          title: this.t('modals.deleteMaterial.title')
        }
      )
      .then(res => {
        if (!res) return;
        PresentationService.deleteElement(this.form.id, item.id)
          .then(() => {
            this.items = this.items.filter(i => i.id !== item.id);
            this.$emit('update');
            this.toast(this.$t('modals.deleteMaterial.deleted', {name: item.name}) as string, 'success');
          })
          .catch(this.showNetworkError);
      });
  }

  uploadDocuments(file: File): void {
    this.currentUploadingFile = file;
    const updateProgress = (progressEvent: ProgressEvent) => this.uploadProgress = progressEvent.loaded / progressEvent.total * 100;
    PresentationService.uploadDocuments(this.form.id, file, this.abortController, updateProgress)
      .then(res => {
        // this endpoint returns the id of the import process that is started
        this.importProcesses.push({
          id: res,
          presentationId: this.form.id,
          progress: 0,
          status: "WAITING",
          fileName: file.name
        })
        this.toast(this.$t('presentation.document.uploaded') as string, 'success');
      })
      .catch(this.showNetworkError)
      .finally(() => {
        this.currentUploadingFile = null;
        this.uploadProgress = 0;
      });
  }

  abortUpload(): void {
    this.abortController.abort();
    this.uploadProgress = 0;
    this.currentUploadingFile = null;
    this.abortController = new AbortController();
  }

  closeProcess(id: number): void {
    PresentationService.closeImportProcess(this.form.id, id)
      .then(() => this.getImportProcesses())
      .catch(this.showNetworkError);
  }

  selectBetween(item: ElementListItem, value: boolean) {
    const index = this.items.findIndex(i => i.id === item.id);
    const selectedIndex = this.items.findIndex(i => i.selected === value && i.id !== item.id);
    let startIndex: number, endIndex: number;
    if (selectedIndex === -1) {
      startIndex = endIndex = index;
    } else {
      startIndex = Math.min(index, selectedIndex);
      endIndex = Math.max(index, selectedIndex);
    }
    this.items.slice(startIndex, endIndex + 1).forEach(i => {
      if (i.selected !== value) this.rowSelected(i, false);
    });
  }

  updateSelection(value: string | null) {
    if (!value) return;
    switch (value) {
      case 'invert':
        this.items.forEach(item => this.rowSelected(item, false));
        this.allSelected = this.items.every(item => item.selected);
        break;
      case 'all':
        this.allSelected = false;
        this.selectAll();
        break;
      case 'none':
        this.allSelected = true;
        this.selectAll();
        break;
    }
  }

  deleteSelectedIds() {
    this.$bvModal
      .msgBoxConfirm(
        this.$t('modals.deleteMaterials.description', {count: this.editIds.length}) as string,
        {
          okVariant: 'danger',
          okTitle: this.t('modals.deleteMaterials.ok'),
          cancelTitle: this.t('common.cancel'),
          centered: true,
          title: this.t('modals.deleteMaterials.title')
        }
      )
      .then(res => {
        if (!res) return;
        PresentationService.deleteSelectedElements(this.form.id, this.editIds)
          .then(() => {
            this.editIds = [];
            this.items = this.items.filter(item => !item.selected);
            this.$emit('update');
            this.toast(this.$t('modals.deleteMaterials.deleted', {count: this.editIds.length}) as string, 'success');
          })
          .catch(this.showNetworkError);
      })
  }

  selectAll(): void {
    this.allSelected = !this.allSelected;
    this.items.forEach(item => {
      if (this.allSelected) {
        if (!item.selected) this.rowSelected(item);
      } else {
        if (item.selected) this.rowSelected(item);
      }
    });
  }

  get editMode(): boolean {
    return this.editIds.length > 0;
  }

  get notesSwitchText(): string {
    return this.$tc('presentation.notesCount', this.items.filter(i => i.notes).length);
  }

  get isUploading(): boolean {
    return this.currentUploadingFile !== null;
  }
}
